import { createSelector, createSlice } from '@reduxjs/toolkit'
import { RootState } from '../../context/store'
import appList from './app-list.json';

export type MyApp = {
    description: string
    identifier: string
    imageAlt: string
    isVisible: boolean
    title: string
    pagePath: string
}

export type AppsState = {
    list: MyApp[]
}

export const AppsInitialState: AppsState = {
    list: appList
}

export const providersSlice = createSlice({
    name: 'apps',
    initialState: AppsInitialState,
    reducers: {
    },
});

// Base Selectors
export const selectString = (_: RootState, s: string) => s;
export const selectAppsBase = (state: RootState) => state.apps.list;

// Reselectors
export const selectApps = createSelector(selectAppsBase, (items) => items);
export const selectAppByIdentifier = createSelector(
    selectAppsBase,
    selectString,
    (items, identifier) => {
        return items.find(i => i.identifier === identifier)
    }
)
export const selectVisibleApps = createSelector(
    selectAppsBase,
    (items) => {
        if (process.env.NODE_ENV === 'production')
            return items.filter(i => i.isVisible === true)
        else
            return items
    }
)

export const { } = providersSlice.actions;
export default providersSlice.reducer;
